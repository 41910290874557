import React, { useState, useEffect } from "react";
import { BasePate } from "../utils/types";
import {
  fetchBasePates,
  updateBasePate,
  deleteBasePate,
  postBasePate,
} from "../utils/api";

const BasePateList = () => {
  const [basepates, setBasePates] = useState<BasePate[]>([]);
  const [basePateName, setBasePateName] = useState("");
  const [newBasePateName, setNewBasePateName] = useState("");
  const trashIcon = "img/trash.png";
  const editIcon = "img/crayon.png";

  const fetchAllBasePate = async () => {
    let fBasePate = await fetchBasePates();
    let tmpBasePate = fBasePate.map((basepate: BasePate) => {
      basepate.isEdit = false;
      return basepate;
    });
    setBasePates(tmpBasePate);
  };
  useEffect(() => {
    fetchAllBasePate();
  }, []);

  const handleDeleteBasePate = async (basepate: BasePate) => {
    if (confirm("Voulez-vous vraiment supprimer cette base de pâte ?")) {
      await deleteBasePate(basepate.id);
      fetchAllBasePate();
    }
  };

  const handleUpdateBasePate = async (basepate: BasePate) => {
    basepate.name = basePateName;
    await updateBasePate(basepate);
    setBasePateName("");
    let tmpBasePates = basepates.map((bp) => {
      bp.isEdit = false;
      return bp;
    });
    setBasePates(tmpBasePates);
  };

  const handleEditBasePate = async (basepate: BasePate) => {
    setBasePateName(basepate.name);
    let tmpBasePate = basepates.map((bp) => {
      if (bp.id === basepate.id) {
        bp.isEdit = bp.isEdit ? false : true;
      } else bp.isEdit = false;

      return bp;
    });
    setBasePates(tmpBasePate);
  };

  const handleAddRecette = async () => {
    let tmpBasePates = [...basepates];
    let newBasePate: BasePate = {
      id: 0,
      name: newBasePateName,
    };
    let newBasePateDb = await postBasePate(newBasePate);
    newBasePate.id = newBasePateDb.id;
    tmpBasePates.push(newBasePate);
    setBasePates(tmpBasePates);
    setNewBasePateName("");
  };

  return (
    <main>
      <div id="basePateListCont">
        <h1>Bases de pâtes</h1>
        <ul id="recetteList">
          {basepates.map((basepate) => {
            return (
              <li key={basepate.id}>
                <div className="recetteListFlexCont">
                  {basepate.isEdit ? (
                    <form
                      className="newRecetteForm"
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleUpdateBasePate(basepate);
                      }}
                    >
                      <input
                        type="text"
                        className="editRecetteInput"
                        placeholder="Nouvelle Recette"
                        value={basePateName}
                        onChange={(e) => {
                          setBasePateName(e.target.value);
                        }}
                      />
                    </form>
                  ) : (
                    <h2>{basepate.name}</h2>
                  )}

                  <div className="recetteListButtonCont">
                    <img
                      onClick={() => handleEditBasePate(basepate)}
                      className="listIcon"
                      src={editIcon}
                      alt="Edit"
                    />

                    <img
                      onClick={() => handleDeleteBasePate(basepate)}
                      className="listIcon"
                      src={trashIcon}
                      alt="Delete"
                    />
                  </div>
                </div>
              </li>
            );
          })}
          <li key="newRecette">
            <div className="recetteListFlexCont">
              <form
                className="newRecetteForm"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleAddRecette();
                }}
              >
                <input
                  type="text"
                  className="newRecetteInput"
                  placeholder="Nouvelle base de pâte"
                  value={newBasePateName}
                  onChange={(e) => {
                    setNewBasePateName(e.target.value);
                  }}
                />
              </form>
            </div>
          </li>
        </ul>
      </div>
    </main>
  );
};

export default BasePateList;
