import React, { useState, useRef, useEffect } from "react";
import { motion, animate } from "framer-motion";
import {
  fetchIngredients,
  postIngredients,
  deleteIngredient,
  updateIngredientIcon,
} from "../utils/api";
import { getIcon } from "../utils/icons";
import { Ingredient, Fournisseur } from "../utils/types";
import "../styles/Ingredients.css";
import FournisseurEditor from "./FournisseurEditor";

export default function Ingredients({}) {
  const newFournisseur: Fournisseur = {
    id: 0,
    name: "",
    tel: "",
    mail: "",
    adress: "",
    website: "",
    contact: "",
    franco: "",
    deliveryTime: "",
    note: "",
  };
  const [ingredients, setIngredients] = useState<Ingredient[]>([]);
  const [isInit, setIsInit] = useState(false);
  const [activeIng, setActiveIng] = useState<Ingredient | null>(null);
  const [ingName, setIngName] = useState("Nom de l'ingrédient");
  const [ingIconBase64, setIngIconBase64] = useState<any>("");
  const [activeFourn, setActiveFourn] = useState<Fournisseur>(newFournisseur);
  const trashImg = "/img/trash.png";

  useEffect(() => {
    if (isInit) return;
    fetchAllIngredients();
    setIsInit(true);
  }, []);

  const fetchAllIngredients = async () => {
    let ingredients = await fetchIngredients();
    let tmpIngredients = ingredients.map((ingredient: Ingredient) => {
      ingredient.isInBowl = false;
      ingredient.poids = 0;
      if (!ingredient.fournisseur) ingredient.fournisseur = newFournisseur;
      return ingredient;
    });
    setIngredients(tmpIngredients);
    setNewIngredient();
  };

  const setNewIngredient = () => {
    setIngName("Nom de l'ingrédient");
    setIngIconBase64(getIcon("new"));
    setActiveFourn(newFournisseur);
    setActiveIng(null);
  };

  const handleIngredientItemClick = (
    event: React.MouseEvent<HTMLDivElement>,
    ingredient: Ingredient
  ) => {
    if (activeIng === ingredient) {
      return setNewIngredient();
    }
    setActiveIng(ingredient);
    if (ingredient.fournisseur) setActiveFourn(ingredient.fournisseur);
    else setActiveFourn(newFournisseur);
    setIngName(ingredient.name);
    setIngIconBase64(ingredient.iconBase64);
  };

  const handleAddIngredient = () => {
    setNewIngredient();
  };

  const handleActiveFourn = (activeFourn: Fournisseur) => {
    setActiveFourn(activeFourn);
    ingredients.map((ingredient) => {
      if (ingredient === activeIng) {
        ingredient.fournisseur = activeFourn;
      }
    });
  };

  const addIngredient = async () => {
    if (ingName === "" || ingName === "Nom de l'ingrédient") return;
    let newIngredient: Ingredient = {
      id: 0,
      name: ingName,
      fournisseur: activeFourn,
      isInBowl: false,
    };
    ingredients.push(newIngredient);
    await saveIngredients();
    setNewIngredient();
  };

  const saveIngredients = async () => {
    ingredients.map((ingredient) => {
      if (ingredient === activeIng) {
        ingredient.name = ingName;
        ingredient.fournisseur = activeFourn;
      }
    });
    let nIconIng = activeIng;
    if (nIconIng && ingIconBase64 !== "") {
      nIconIng.iconBase64 = ingIconBase64;
      await updateIngredientIcon(nIconIng);
    }
    await postIngredients(ingredients);
    let addedIngredient = await fetchIngredients();
    let tmpIngredients = addedIngredient.map((ingredient: Ingredient) => {
      if (activeIng && ingredient.id === activeIng.id) {
        setActiveIng(ingredient);
      }
      ingredient.isInBowl = false;
      ingredient.poids = 0;
      if (!ingredient.fournisseur) ingredient.fournisseur = newFournisseur;
      return ingredient;
    });
    setIngredients(tmpIngredients);
  };

  const handleDelIng = async () => {
    if (!activeIng) return;
    if (confirm("Voulez-vous vraiment supprimer cet ingrédient ?")) {
      await deleteIngredient(activeIng.id);
      fetchAllIngredients();
    }
  };

  const photoUpload = (e: any) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (reader !== undefined && file !== undefined) {
      reader.onloadend = () => {
        setIngIconBase64(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleIngIcon = async () => {};

  return (
    <main>
      <motion.div className="ing-flexCont">
        <motion.div exit={{ x: 300, opacity: 0 }} id="ingListCont">
          <h1>Ingredients</h1>
          <div className="ingredientList">
            {ingredients.map((ingredient: Ingredient) => (
              <motion.div
                className={
                  activeIng === ingredient
                    ? "active ingredientItem"
                    : "ingredientItem"
                }
                key={ingredient.name}
                whileHover={{
                  scale: 1.2,
                  transition: { duration: 0.4 },
                }}
                onClick={(e) => handleIngredientItemClick(e, ingredient)}
              >
                <img
                  className="ingredientIcon"
                  src={
                    ingredient.iconBase64
                      ? ingredient.iconBase64
                      : getIcon("new")
                  }
                />
                <div className="ingredientName">{ingredient.name}</div>
                <div className="ingFournName">
                  {ingredient.fournisseur && ingredient.fournisseur.name ? (
                    `(${ingredient.fournisseur.name})`
                  ) : (
                    <>&nbsp;</>
                  )}
                </div>
              </motion.div>
            ))}
            <motion.div
              className="ingredientItem newIngredientItem"
              key="addIngredient"
              onClick={() => handleAddIngredient()}
              whileHover={{
                scale: 1.2,
                opacity: 1,
                transition: { duration: 0.4 },
              }}
            >
              <img className="ingredientIcon" src={getIcon("new")} />
              <div className="ingredientName">?</div>
            </motion.div>
          </div>
        </motion.div>
        <motion.div
          initial={{ x: 300, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          id="ing-detCont"
        >
          <div id="ingDetFormCont">
            {activeIng ? (
              <button
                className="delIng"
                onClick={() => handleDelIng()}
                title="Alors on la jette"
              >
                <img src={trashImg} />
              </button>
            ) : (
              ""
            )}
            <div id="ingDetFormWrapper">
              <div className="ingIconWrapper">
                <img
                  className="ingIcon"
                  src={ingIconBase64 != "" ? ingIconBase64 : getIcon("new")}
                  onClick={() => handleIngIcon()}
                />
                <input
                  type="file"
                  className="ingIconInput"
                  name="icon"
                  id="file"
                  accept=".jpef, .png, .jpg .jpeg"
                  onChange={photoUpload}
                  src={ingIconBase64}
                />
              </div>
              <div className="ingNameInputCont">
                <div className="ingNameInputFakeBorder">
                  <div className="ingNameInputFake">{ingName}</div>
                </div>
                <input
                  type="text"
                  className="ingNameInputTrue"
                  value={ingName}
                  onChange={(e) => setIngName(e.target.value)}
                />
              </div>
            </div>
            <FournisseurEditor
              activeFourn={activeFourn}
              handleActiveFourn={handleActiveFourn}
            />
            <motion.div
              whileHover={{
                scale: 1.2,
              }}
              whileTap={{
                scale: 0.9,
              }}
              className="saveBtn"
              onClick={() => {
                activeIng ? saveIngredients() : addIngredient();
              }}
            >
              {activeIng ? "Enregistrer" : "Ajouter"}
            </motion.div>
          </div>
        </motion.div>
      </motion.div>
    </main>
  );
}
