import { useEffect, useState } from "react";
import { fetchRecettes, postNewRecette, deleteRecette } from "../utils/api";
import { Recette } from "../utils/types";
import "../styles/RecettesList.css";

const RecettesList = () => {
  const [recettes, setRecettes] = useState<Recette[]>([]);
  const [newRecetteName, setNewRecetteName] = useState("");
  const pateIcon = "img/balance.png";
  const articleIcon = "img/sac.png";
  const trashIcon = "img/trash.png";
  const infosIcon = "img/infos.png";

  const fetchAllRecettes = async () => {
    let fRecettes = await fetchRecettes();
    let tmpRecettes = fRecettes.map((recette: Recette) => {
      return recette;
    });
    setRecettes(tmpRecettes);
  };
  useEffect(() => {
    fetchAllRecettes();
  }, []);

  const handleAddRecette = async () => {
    let tmpRecettes = [...recettes];
    let newRecette: Recette = {
      id: 0,
      name: newRecetteName,
      poids: [],
    };
    let newRecetteDb = await postNewRecette(newRecette);
    newRecette.id = newRecetteDb.id;
    tmpRecettes.push(newRecette);
    setRecettes(tmpRecettes);
    setNewRecetteName("");
  };

  const handleDeleteRecette = async (recette: Recette) => {
    if (confirm("Voulez-vous vraiment supprimer cette recette ?")) {
      await deleteRecette(recette.id);
      fetchAllRecettes();
    }
  };

  return (
    <main>
      <div id="recetteListCont">
        <h1>Recettes</h1>
        <ul id="recetteList">
          {recettes.map((recette) => {
            return (
              <li key={recette.id}>
                <div className="recetteListFlexCont">
                  <a
                    className="lnkRecetteListTitle"
                    href={"/recette/" + recette.id}
                  >
                    {recette.name}
                  </a>
                  <div className="recetteListButtonCont">
                    <a href={"/recette/public/" + recette.id}>
                      <img
                        className="listIcon"
                        src={infosIcon}
                        alt="Infos"
                        title="Editer les infos"
                      />
                    </a>
                    <a href={"/articles/#rct-" + recette.id}>
                      <img
                        className="listIcon"
                        src={articleIcon}
                        alt="Article ouverte"
                        title="Editer les articles"
                      />
                    </a>
                    <a href={"/recette/poids/" + recette.id}>
                      <img
                        className="listIcon"
                        src={pateIcon}
                        alt="List ouverte"
                        title="Editer les proportions"
                      />
                    </a>
                    <img
                      onClick={() => handleDeleteRecette(recette)}
                      className="listIcon"
                      src={trashIcon}
                      alt="Delete"
                    />
                  </div>
                </div>
              </li>
            );
          })}

          <li key="newRecette">
            <div className="recetteListFlexCont">
              <form
                className="newRecetteForm"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleAddRecette();
                }}
              >
                <input
                  type="text"
                  className="newRecetteInput"
                  placeholder="Nouvelle Recette"
                  value={newRecetteName}
                  onChange={(e) => {
                    setNewRecetteName(e.target.value);
                  }}
                />
              </form>
            </div>
          </li>
        </ul>
      </div>
    </main>
  );
};

export default RecettesList;
