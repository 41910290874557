import "./styles/global.css";
import Recettes from "./components/Recette";
import Home from "./components/Home";
import Header from "./components/Header";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Ingredient from "./components/Ingredient";
import RecettesList from "./components/RecettesList";
import BasePateList from "./components/BasePateList";
import Commandes from "./components/Commandes";
import Articles from "./components/Articles";
import PublicRecette from "./components/PublicRecette";

import { Provider } from "react-redux";
import { store } from "./store";
import { Dashboard } from "./components/Dashboard";

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/recettes" element={<RecettesList />} />
          <Route
            path="/recette/public/:recetteId/"
            element={<PublicRecette />}
          />
          <Route path="/recette/:recetteId/" element={<Recettes />} />
          <Route
            path="/recette/poids/:recetteId/"
            element={<Recettes iniPageRecette="poids" />}
          />
          <Route path="/ingredients" element={<Ingredient />} />
          <Route path="/basepate" element={<BasePateList />} />
          <Route path="/commandes" element={<Commandes />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
        <a
          href="https://plane.skapmedia.xyz/projets/projects/8be41c2f-9262-4890-9b52-6937a2121979/views/0062987a-d609-4476-8dd5-f931f8854d1b"
          target="blank"
          className="bug-report"
        >
          Signaler un bug
        </a>
      </Router>
    </Provider>
  );
};

export default App;
