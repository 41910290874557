import axios from "axios";

var baseUrl =
  process.env.REACT_APP_API_BASE_URL || "https://api.chezlaurette.cool";

/* Ingredient */
export const fetchIngredients = async () => {
  return axios.get(baseUrl + "/ingredient").then((response) => response.data);
};

export const postIngredients = async (ingredients: any) => {
  return axios
    .post(baseUrl + "/ingredient/many", ingredients)
    .then((response) => response.data);
};

export const deleteIngredient = async (id: any) => {
  return axios
    .delete(baseUrl + "/ingredient/" + id)
    .then((response) => response.data);
};

export const updateIngredientIcon = async (ingredient: any) => {
  return axios
    .patch(baseUrl + "/ingredient/icon/" + ingredient.id, {
      iconBase64: ingredient.iconBase64,
    })
    .then((response) => response.data);
};

/* Recette */
export const updateRecette = async (recette: any) => {
  return axios
    .patch(baseUrl + "/recette/" + recette.id, recette)
    .then((response) => response.data);
};

export const postNewRecette = async (recette: any) => {
  return axios
    .post(baseUrl + "/recette", recette)
    .then((response) => response.data);
};

export const fetchRecettes = async () => {
  return axios.get(baseUrl + "/recette").then((response) => response.data);
};

export const fetchRecette = async (id: any) => {
  return axios
    .get(baseUrl + "/recette/" + id)
    .then((response) => response.data);
};

export const deleteRecette = async (id: any) => {
  return axios
    .delete(baseUrl + "/recette/" + id)
    .then((response) => response.data);
};

/* Services */
export const fetchServices = async (from: string, to: string) => {
  return axios
    .post(baseUrl + "/service/range", {
      from: from,
      to: to,
    })
    .then((response) => response.data);
};

export const genExport = async (from: string, to: string) => {
  return axios
    .post(baseUrl + "/service/export", {
      from: from,
      to: to,
    })
    .then((response) => response.data);
};

/* BasePate */
export const fetchBasePates = async () => {
  return axios.get(baseUrl + "/basepate").then((response) => response.data);
};

export const postBasePate = async (basepate: any) => {
  return axios
    .post(baseUrl + "/basepate", basepate)
    .then((response) => response.data);
};

export const updateBasePate = async (basepate: any) => {
  return axios
    .patch(baseUrl + "/basepate/" + basepate.id, basepate)
    .then((response) => response.data);
};

export const deleteBasePate = async (id: any) => {
  return axios
    .delete(baseUrl + "/basepate/" + id)
    .then((response) => response.data);
};

export const fetchCurrentCommandes = async () => {
  return axios
    .get(baseUrl + "/commande/current")
    .then((response) => response.data);
};

export const patchCommande = async (id: number, patchCommande: any) => {
  return axios
    .patch(baseUrl + "/commande/" + id, patchCommande)
    .then((response) => response.data);
};
