import { useEffect, useState } from "react";
import { fetchBasePates } from "../utils/api";
import { BasePate } from "../utils/types";
import "../styles/BasePate.css";

interface BasePateProps {
  noteRecette: string;
  setNoteRecette: (noteRecette: string) => void;
}

const BasePateElement = ({ noteRecette, setNoteRecette }: BasePateProps) => {
  return (
    <div id="BasePateWrapper">
      <h1>Informations complémentaires</h1>
      <div className="recetteNote">
        <h3>Notes</h3>
        <textarea
          className="ingFournNoteInput"
          value={noteRecette}
          onChange={(e) => setNoteRecette(e.target.value)}
        />
      </div>
    </div>
  );
};

export default BasePateElement;
