import React, { useState, useRef, useEffect } from "react";
import { Fournisseur } from "../utils/types";

interface FournisseurEditorProps {
  activeFourn: Fournisseur | null;
  handleActiveFourn: (activeFourn: Fournisseur) => void;
}

const FournisseurEditor = ({
  activeFourn,
  handleActiveFourn,
}: FournisseurEditorProps) => {
  const handleFournDetChange = (value: string, fournDetKey: string) => {
    if (!activeFourn) return;
    let tmpFournisseur: Fournisseur = { ...activeFourn };
    // @ts-ignore
    tmpFournisseur[fournDetKey] = value;
    handleActiveFourn(tmpFournisseur);
  };
  const notDet = ["id", "name", "note"];
  const fournLabel = {
    tel: "Téléphone",
    mail: "Mail",
    adress: "Adresse",
    website: "Site web",
    contact: "Contact",
    franco: "Franco",
    deliveryTime: "Délai de livraison",
  };

  return (
    <div className="ingFournCont">
      <div className="ingFournWrapper">
        <input
          type="text"
          className="ingFournisseurInput"
          placeholder="Fournisseur"
          value={activeFourn ? activeFourn.name : ""}
          onChange={(e) => {
            handleFournDetChange(e.target.value, "name");
          }}
        />
        <div className="ingFournisseurScroll">
          {activeFourn &&
            Object.keys(activeFourn).map((fournDet: string) =>
              notDet.indexOf(fournDet) < 0 ? (
                <div className="ingFournInpCont" key={fournDet}>
                  <label
                    className="ingFournInpLabel"
                    htmlFor={"ingFournInp-" + fournDet}
                  >
                    {fournLabel[fournDet as keyof typeof fournLabel]}:
                  </label>
                  <input
                    type="text"
                    className="ingFournInp"
                    id={"ingFournInp-" + fournDet}
                    value={
                      activeFourn
                        ? activeFourn[fournDet as keyof typeof activeFourn]
                        : ""
                    }
                    onChange={(e) =>
                      handleFournDetChange(e.target.value, fournDet)
                    }
                  />
                  <div className="ingFournInpBorder"></div>
                </div>
              ) : (
                ""
              )
            )}
        </div>
      </div>
      <div className="ingFournNote">
        <h3>Notes</h3>
        <textarea
          className="ingFournNoteInput"
          value={activeFourn ? (activeFourn.note ? activeFourn.note : "") : ""}
          onChange={(e) => handleFournDetChange(e.target.value, "note")}
        />
      </div>
    </div>
  );
};
export default FournisseurEditor;
