import React, { useState, useRef, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { motion, animate } from "framer-motion";
import { getIcon } from "../utils/icons";
import {
  fetchIngredients,
  postNewRecette,
  fetchRecette,
  updateRecette,
} from "../utils/api";
import "../styles/Recette.css";
import RecetteIngredientList from "./RecetteIngredientList";
import BasePateElement from "./BasePateElement";
import { Ingredient, Poids, Recette, BasePate } from "../utils/types";

export default function Recettes({
  iniPageRecette = "recettes",
}: {
  iniPageRecette?: string;
}) {
  const [ingredients, setIngredients] = useState<Ingredient[]>([]);
  const [isInit, setIsInit] = useState(false);
  const [pageRecette, setPageRecette] = useState(iniPageRecette);
  const [recetteName, setRecetteName] = useState("");
  const [noteRecette, setNoteRecette] = useState("");
  const [currRecette, setCurrRecette] = useState<Recette>();

  const [poidsBasepate, setPoidsBasepate] = useState(0);

  const ingredientRef = useRef({} as { [key: string]: HTMLDivElement });
  const constraintsRef = useRef(null);
  const droppableRef = useRef(null);
  const ingListRef = useRef(null);
  let { recetteId } = useParams();
  const navigate = useNavigate();

  const timeout = (delay: number) =>
    new Promise((res) => setTimeout(res, delay));

  const waitForRender = async () =>
    new Promise<void>(async (done) => {
      if (pageRecette !== "recettes") return done();
      while (
        Object.values(ingredientRef.current).length === 0 &&
        ingredients.length === 0
      ) {
        console.log("waiting");
        await timeout(10);
      }
      done();
    });

  useEffect(() => {
    window.addEventListener("resize", () => {
      console.log("resize");
      animateIngredient();
    });
    if (isInit) return;
    fetchIngredients().then((ingredients) => {
      let tmpIngredients = ingredients.map((ingredient: Ingredient) => {
        ingredient.isInBowl = false;
        ingredient.poids = 0;
        return ingredient;
      });
      setIngredients(tmpIngredients);

      if (recetteId) {
        fetchRecette(recetteId).then((recette) => {
          setCurrRecette(recette);
          setRecetteName(recette.name);
          setNoteRecette(recette.note);
          recette.basepate;
          let tmpIngredients = ingredients.map((ingredient: Ingredient) => {
            recette.poids.forEach((poids: Poids) => {
              if (ingredient.id === poids.ingredient.id) {
                ingredient.poids = poids.poids;
                ingredient.isInBowl = true;
              }
            });
            return ingredient;
          });
          setIngredients(tmpIngredients);
        });
      }
      if (tmpIngredients.length === 0) {
        setIsInit(true);
        return;
      }
      waitForRender().then(() => {
        console.log("rendered");
        setIsInit(true);
        animateIngredient();
      });
    });
  }, [waitForRender]);

  const handlePostRecette = async () => {
    let recette: Recette = {
      name: recetteName,
      note: noteRecette,
      poidsBasePate: poidsBasepate,
      poids: ingredients.reduce((acc, ing) => {
        if (ing.isInBowl)
          acc.push({
            ingredient: {
              id: ing.id,
              name: ing.name,
            },
            poids: Number(ing.poids),
          });
        return acc;
      }, [] as Poids[]),
    };
    if (currRecette?.basepate) {
      recette.basepate = {
        id: currRecette?.basepate?.id,
        name: currRecette?.basepate?.name,
      };
    }
    if (recetteId) {
      recette.id = Number(recetteId);
      return await updateRecette(recette);
    }
    await postNewRecette(recette);
  };

  const animateIngredient = (animatedIngredients = ingredients) => {
    console.log("animate", animatedIngredients);
    animatedIngredients.forEach((ingredient: Ingredient, indexItem: number) => {
      if (ingredient.isInBowl) {
        if (droppableRef.current) {
          const dropElm = droppableRef.current as HTMLElement;
          const droppableRect = dropElm.getBoundingClientRect();
          let indexList: string[] = animatedIngredients.reduce((acc, ing) => {
            if (ing.isInBowl) acc.push(ing.name);
            return acc;
          }, [] as string[]);
          let indexBowl = indexList.indexOf(ingredient.name);
          let lineIndex = Math.floor(indexBowl / 6);
          let rowIndex = indexBowl - lineIndex * 6;
          animate(ingredientRef.current[ingredient.name], {
            x: droppableRect.left - 75 + rowIndex * 80,
            y: droppableRect.top - 400 - indexItem * 80 + lineIndex * 90,
          });
        }
      } else {
        if (constraintsRef.current) {
          const constElm = constraintsRef.current as HTMLElement;
          const constBound = constElm.getBoundingClientRect();
          let colCount = Math.floor(constBound.width / 2 / 80);
          let lineIndex = Math.floor(indexItem / colCount);
          let rowIndex = indexItem - lineIndex * colCount;
          animate(ingredientRef.current[ingredient.name], {
            x: 0 + rowIndex * 80,
            y: 0 - indexItem * 80 + lineIndex * 90,
          });
        }
      }
    });
  };

  const handleSetIngredients = async (ingredientsList: Ingredient[]) => {
    setIngredients(ingredientsList);
    if (pageRecette !== "recettes") return;
    while (
      Object.values(ingredientRef.current).length !== ingredientsList.length
    ) {
      await timeout(50);
      console.log("wait for render");
    }
    animateIngredient(ingredientsList);
  };

  return (
    <main>
      <motion.div className="rct-flexCont" ref={constraintsRef}>
        {pageRecette === "recettes" && (
          <motion.div exit={{ x: 300, opacity: 0 }}>
            <div>
              <figure className="bolIcon" ref={droppableRef}>
                <img src="/img/bol-front.png" alt="bolf" className="bolf" />
                <img src="/img/bol-back.png" alt="bolb" className="bolb" />
              </figure>
            </div>
            <div className="ingElmCont">
              {ingredients.map((ingredient: Ingredient, index: number) => (
                <motion.div
                  className="ingRnd"
                  drag
                  ref={(el) => {
                    if (el) ingredientRef.current[ingredient.name] = el;
                  }}
                  key={ingredient.name}
                  data-name={ingredient.name}
                  title={`${ingredient.name} ${
                    ingredient.fournisseur
                      ? "(" + ingredient.fournisseur.name + ")"
                      : ""
                  }`}
                  dragConstraints={constraintsRef}
                  whileDrag={{ zIndex: 2, position: "relative" }}
                  dragMomentum={false}
                  onDrag={(event, info) => {
                    if (droppableRef.current) {
                      const dropElm = droppableRef.current as HTMLElement;
                      const droppableRect = dropElm.getBoundingClientRect();
                      if (
                        info.point.x >= droppableRect.left &&
                        info.point.x <= droppableRect.left + 512 &&
                        info.point.y >= droppableRect.top &&
                        info.point.y <= droppableRect.top + 336
                      ) {
                        dropElm.style.background = "#ffec67";
                        dropElm.style.borderColor = "#ffec67";
                      } else {
                        dropElm.style.background = "#ffbf4b";
                        dropElm.style.borderColor = "#ffbf4b";
                      }
                    }
                  }}
                  onDragEnd={(event, info) => {
                    if (droppableRef.current) {
                      const dropElm = droppableRef.current as HTMLElement;
                      const droppableRect = dropElm.getBoundingClientRect();
                      dropElm.style.background = "#ffbf4b";
                      dropElm.style.borderColor = "#ffbf4b";
                      const targetElm = event.target as HTMLElement;
                      if (
                        info.point.x >= droppableRect.left &&
                        info.point.x <= droppableRect.left + 512 &&
                        info.point.y >= droppableRect.top &&
                        info.point.y <= droppableRect.top + 336
                      ) {
                        let tmpIngredients = ingredients.map((ing) => {
                          if (ing.name === targetElm.dataset.name) {
                            ing.isInBowl = true;
                          }
                          return ing;
                        });
                        console.log(tmpIngredients);
                        handleSetIngredients(tmpIngredients);
                      } else {
                        let tmpIngredients = ingredients.map((ing) => {
                          if (ing.name === targetElm.title) {
                            ing.isInBowl = false;
                          }
                          return ing;
                        });
                        handleSetIngredients(tmpIngredients);
                      }
                    }
                  }}
                  style={{
                    backgroundImage: `url(${
                      ingredient.iconBase64 != ""
                        ? ingredient.iconBase64
                        : getIcon("new")
                    })`,
                  }}
                ></motion.div>
              ))}
            </div>
          </motion.div>
        )}
        <motion.div
          ref={ingListRef}
          id="ingListCont"
          initial={{ x: 300, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: 300, opacity: 0 }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20,
          }}
        >
          <div id="ingListWrapper">
            <input
              type="text"
              placeholder="Nom de la recette"
              className="recetteName"
              value={recetteName}
              onChange={(e) => setRecetteName(e.target.value)}
            />
            {currRecette && (
              <RecetteIngredientList
                currRecette={currRecette}
                ingredients={ingredients}
                handleSetIngredients={handleSetIngredients}
                pageRecette={pageRecette}
                poidsBasepate={poidsBasepate}
                setPoidsBasepate={setPoidsBasepate}
              />
            )}
          </div>
        </motion.div>
        {pageRecette === "poids" && (
          <motion.div>
            <BasePateElement
              noteRecette={noteRecette}
              setNoteRecette={setNoteRecette}
            />
          </motion.div>
        )}
      </motion.div>
      <motion.div
        whileHover={{
          scale: 1.2,
        }}
        whileTap={{
          scale: 0.9,
        }}
        className="nextBtn"
        onClick={() => {
          if (pageRecette === "recettes") {
            navigate("/recette/poids/" + (recetteId ? recetteId : ""));
            setPageRecette("poids");
          }
          if (pageRecette === "poids") handlePostRecette();
        }}
      >
        {pageRecette === "recettes" ? "Suivant" : "Enregistrer"}
      </motion.div>
    </main>
  );
}
