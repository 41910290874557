import React, { useEffect, useState } from "react";
import { getIcon } from "../utils/icons";
import { fetchBasePates } from "../utils/api";
import { Ingredient, BasePate, Recette, Poids } from "../utils/types";

interface IngredientListProps {
  currRecette: Recette;
  ingredients: Ingredient[];
  handleSetIngredients: (ingredients: Ingredient[]) => void;
  pageRecette: string;
  poidsBasepate: number;
  setPoidsBasepate: (poidsBasepate: number) => void;
}

const RecetteIngredientList = ({
  currRecette,
  ingredients,
  handleSetIngredients,
  pageRecette,
  poidsBasepate,
  setPoidsBasepate,
}: IngredientListProps) => {
  const [currentSearch, setCurrentSearch] = useState("");
  const [ingredientSearch, setIngredientSearch] = useState<Ingredient[]>([]);
  const [totalPoids, setTotalPoids] = useState(0);
  const [estTotalPoids, setEstTotalPoids] = useState(0);
  const [basepates, setBasePates] = useState<BasePate[]>([]);
  const [isBasePateOpen, setIsBasePateOpen] = useState(false);
  const [selectedBasePate, setSelectedBasePate] = useState<BasePate | null>(
    null
  );

  useEffect(() => {
    let tmpTotal = 0;
    ingredients.map((ing) => {
      if (ing.isInBowl) tmpTotal += Number(ing.poids);
      return ing;
    });
    setTotalPoids(tmpTotal);
    setEstTotalPoids(Math.round(tmpTotal * 0.8));
    setSelectedBasePate(currRecette.basepate || null);
    if (currRecette.basepate && currRecette.poidsBasePate)
      setPoidsBasepate(currRecette.poidsBasePate);

    fetchBasePates().then((basepates: BasePate[]) => {
      setBasePates(basepates);
    });
  }, []);

  const handleIngSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentSearch(event.target.value);
    const search = event.target.value.toLowerCase();
    if (search.length === 0) return setIngredientSearch([]);
    const filteredIngredients = ingredients.filter(
      (ingredient) =>
        ingredient.name.toLowerCase().includes(search) && !ingredient.isInBowl
    );
    setIngredientSearch(filteredIngredients);
  };

  const handleSubmit = async () => {
    if (ingredientSearch.length > 0) {
      let tmpIngredients = ingredients.map((ing) => {
        if (ing.name === ingredientSearch[0].name) {
          ing.isInBowl = true;
        }
        return ing;
      });
      setCurrentSearch("");
      setIngredientSearch([]);
      return handleSetIngredients(tmpIngredients);
    }
    let tmpIngredients = [...ingredients];
    tmpIngredients.push({ name: currentSearch, isInBowl: true, poids: 0 });
    handleSetIngredients(tmpIngredients);
    setCurrentSearch("");
    setIngredientSearch([]);
  };

  const handleChangePoids = (
    event: React.ChangeEvent<HTMLInputElement>,
    ingredientName: string
  ) => {
    let tmpTotal = 0;
    let tmpIngredients = ingredients.map((ing) => {
      if (ing.name === ingredientName) {
        ing.poids = Number(event.target.value);
      }
      if (ing.isInBowl) tmpTotal += Number(ing.poids);
      return ing;
    });
    setTotalPoids(tmpTotal);
    setEstTotalPoids(Math.round(tmpTotal * 0.8));
    handleSetIngredients(tmpIngredients);
  };

  const handleSelect = (event: React.MouseEvent<HTMLInputElement>) => {
    event.currentTarget.select();
  };

  return (
    <ul id="ingredientList">
      <li id="searchIngCont">
        <input
          type="text"
          className="searchInput"
          placeholder="Ajouter un ingrédient"
          onChange={(e) => handleIngSearch(e)}
          onKeyUp={(e) => (e.key === "Enter" ? handleSubmit() : null)}
          value={currentSearch}
        />
        <ul id="searchList">
          {ingredientSearch.map((ingredient: Ingredient, index: number) => (
            <li
              key={ingredient.name}
              onClick={() => {
                let tmpIngredients = ingredients.map((ing) => {
                  if (ing.name === ingredient.name) {
                    ing.isInBowl = true;
                  }
                  return ing;
                });
                handleSetIngredients(tmpIngredients);
                setCurrentSearch("");
                setIngredientSearch([]);
              }}
            >
              {ingredient.name}
            </li>
          ))}
          {ingredientSearch.length === 0 && currentSearch.length > 0 && (
            <li>
              <img src={getIcon("new")} alt={"new"} className="ingListIcon" />
              <p className="ingListText">{currentSearch}</p>
            </li>
          )}
        </ul>
        <select
          id="BasePateSelect"
          value={
            selectedBasePate != null
              ? currRecette.basepate && currRecette.basepate.id
              : 0
          }
          onChange={(e) => {
            let tmpBasePate = basepates.find(
              (basepate) => basepate.id === Number(e.target.value)
            );
            setSelectedBasePate(tmpBasePate || null);
            currRecette.basepate = tmpBasePate || undefined;
          }}
        >
          <option value="0">Aucune base</option>
          {basepates.map((basepate: BasePate) => {
            return (
              <option value={basepate.id} key={basepate.id}>
                {basepate.name}
              </option>
            );
          })}
        </select>
      </li>
      {currRecette.basepate && (
        <>
          <li key={currRecette.basepate.id}>
            <img
              src={getIcon("totalPate")}
              alt="basepate"
              className="ingListIcon"
            />
            <p
              className="ingListText"
              style={pageRecette === "recette" ? { flexGrow: 1 } : {}}
            >
              {currRecette.basepate.name}
            </p>
            {pageRecette === "poids" && (
              <div className="ingListPoidsCont">
                <input
                  type="text"
                  className="ingListPoids"
                  value={poidsBasepate}
                  onClick={handleSelect}
                  onChange={(e) => {
                    setPoidsBasepate(Number(e.target.value));
                    currRecette.poidsBasePate = Number(e.target.value);
                  }}
                />
                <span className="ingListPoidsG">g</span>
              </div>
            )}
            <img
              src={isBasePateOpen ? "/img/liste.png" : "/img/liste-line.png"}
              alt="liste"
              className="removeIng"
              onClick={(e) => setIsBasePateOpen(!isBasePateOpen)}
            />
          </li>
          {isBasePateOpen && (
            <li>
              <ul className="basePateIngList">
                {currRecette.basepate &&
                  currRecette.basepate.poids &&
                  currRecette.basepate.poids.map(
                    (poids: Poids, index: number) => {
                      let ingredient = poids.ingredient;
                      return (
                        <li key={index}>
                          <img
                            src={
                              ingredient.iconBase64 != ""
                                ? ingredient.iconBase64
                                : getIcon("new")
                            }
                            alt={ingredient.name}
                            className="ingListIcon"
                          />
                          <p
                            className="ingListText"
                            style={
                              pageRecette === "recette" ? { flexGrow: 1 } : {}
                            }
                          >
                            {ingredient.name}
                          </p>
                          {pageRecette === "poids" && (
                            <div className="ingListPoidsCont">
                              <p className="ingListPoids">{poids.poids}</p>
                              <span className="ingListPoidsG">g</span>
                            </div>
                          )}
                        </li>
                      );
                    }
                  )}
              </ul>
            </li>
          )}
        </>
      )}
      {ingredients.map((ingredient: Ingredient, index: number) => {
        if (!ingredient.isInBowl) return null;
        return (
          <li key={index}>
            <img
              src={
                ingredient.iconBase64 != ""
                  ? ingredient.iconBase64
                  : getIcon("new")
              }
              alt={ingredient.name}
              className="ingListIcon"
            />
            <p
              className="ingListText"
              style={pageRecette === "recette" ? { flexGrow: 1 } : {}}
            >
              {ingredient.name}
            </p>
            {pageRecette === "poids" && (
              <div className="ingListPoidsCont">
                <input
                  type="text"
                  className="ingListPoids"
                  value={ingredient.poids}
                  onClick={handleSelect}
                  onChange={(e) => handleChangePoids(e, ingredient.name)}
                />
                <span className="ingListPoidsG">g</span>
              </div>
            )}
            <img
              src="/img/trash.png"
              alt="trash"
              className="removeIng"
              onClick={(e) => {
                let tmpTotal = 0;
                let tmpIngredients = ingredients.map((ing) => {
                  if (ing.name === ingredient.name) {
                    ing.isInBowl = false;
                    ing.poids = 0;
                  }
                  tmpTotal += Number(ing.poids);
                  return ing;
                });
                setTotalPoids(tmpTotal);
                setEstTotalPoids(Math.round(tmpTotal * 0.8));
                handleSetIngredients(tmpIngredients);
              }}
            />
          </li>
        );
      })}
      {pageRecette === "poids" && (
        <li key="totalPate" className="totalPateLi">
          <img
            src={getIcon("totalPate")}
            alt={"totalPate"}
            className="ingListIcon"
          />
          <p className="ingListText">Total pâte</p>
          <div
            style={{
              padding: "7px 16px 6px",
            }}
          >
            <p className="ingListPoids">{totalPoids}</p>
            <span className="ingListPoidsG">g</span>
          </div>
        </li>
      )}
      {pageRecette === "poids" && (
        <li key="estPoidsPate" className="totalPateLi">
          <img
            src={getIcon("pain")}
            alt={"estPoidsPate"}
            className="ingListIcon"
          />
          <p className="ingListText">Poids pâte après cuisson</p>
          <div
            style={{
              padding: "7px 16px 6px",
            }}
          >
            <p className="ingListPoids">{estTotalPoids}</p>
            <span className="ingListPoidsG">g</span>
          </div>
        </li>
      )}
    </ul>
  );
};

export default RecetteIngredientList;
