export const getIcon = (name: string) => {
  switch (name) {
    case "Farine T80":
      return "/img/farine.png";
    case "Eau":
      return "/img/eau.png";
    case "Sel":
      return "/img/sel.png";
    case "Levain":
      return "/img/levain.png";
    case "Lait":
      return "/img/lait.png";
    case "Oeuf":
      return "/img/oeuf.png";
    case "Graines":
      return "/img/graines.png";
    case "new":
      return "/img/new.png";
    case "totalPate":
      return "/img/pate.png";
    case "pain":
      return "/img/pain.png";
    default:
      return "/img/new.png";
  }
};
