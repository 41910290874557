import { useEffect, useState } from "react";
import { fetchServices, genExport } from "../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import { setServices } from "../redux/serviceSlice";
import { Service } from "../utils/types";
import { format, lastDayOfMonth } from "date-fns";
import { fr } from "date-fns/locale";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  LineChart,
  Line,
  LabelList,
  ResponsiveContainer,
} from "recharts";

import "../styles/Dashboard.css";

export const Dashboard = () => {
  const dispatch = useDispatch<AppDispatch>();
  const services = useSelector((state: RootState) => state.service.services);
  const today = new Date();
  const initDate = new Date("2023-09-20");
  const [startDate, setStartDate] = useState<string>(
    format(initDate, "yyyy-MM-01")
  );
  const [endDate, setEndDate] = useState<string>(
    format(lastDayOfMonth(today), "yyyy-MM-dd")
  );
  const [tab, setTab] = useState<string>("service");

  useEffect(() => {
    fetchServices(startDate, endDate).then((services) => {
      dispatch(setServices(services));
    });
  }, []);

  const ServiceTab = () => {
    type ServData = {
      name: string;
      week?: string;
      ca: number;
    };
    const [serviceType, setServiceType] = useState<string>("day");
    const [servData, setServData] = useState<ServData[]>([]);

    useEffect(() => {
      updateServData();
    }, [serviceType]);

    const updateServData = () => {
      let tmpServData = [] as ServData[];
      let servFiltData = services
        .filter(
          (service: Service) =>
            service.serviceDate >= startDate &&
            service.serviceDate <= endDate &&
            (service.paiements.length > 0 || service.mouvements.length > 0)
        )
        .sort((a: Service, b: Service) =>
          a.serviceDate > b.serviceDate ? 1 : -1
        );

      if (serviceType === "month") {
        tmpServData = servFiltData.reduce(
          (acc: ServData[], service: Service) => {
            let monthNumber = format(new Date(service.serviceDate), "MMMM", {
              locale: fr,
            });
            let monthIndex = acc.findIndex((serv) => serv.name === monthNumber);
            if (monthIndex === -1) {
              acc.push({
                name: monthNumber,
                ca: service.paiements.reduce(
                  (acc, paiement) => (acc += Number(paiement.amount)),
                  0
                ),
              });
            } else {
              acc[monthIndex].ca += service.paiements.reduce(
                (acc, paiement) => (acc += Number(paiement.amount)),
                0
              );
            }
            return acc;
          },
          [] as ServData[]
        );
      }
      if (serviceType === "week") {
        tmpServData = servFiltData.reduce(
          (acc: ServData[], service: Service) => {
            let weekNumber = format(new Date(service.serviceDate), "w", {
              locale: fr,
            });
            let weekIndex = acc.findIndex((serv) => serv.week === weekNumber);
            if (weekIndex === -1) {
              acc.push({
                name: format(new Date(service.serviceDate), "#w dd/MM", {
                  locale: fr,
                }),
                week: weekNumber,
                ca: service.paiements.reduce(
                  (acc, paiement) => (acc += Number(paiement.amount)),
                  0
                ),
              });
            } else {
              acc[weekIndex].ca += service.paiements.reduce(
                (acc, paiement) => (acc += Number(paiement.amount)),
                0
              );
            }
            return acc;
          },
          [] as ServData[]
        );
      }
      if (serviceType === "day") {
        tmpServData = servFiltData.map((service: Service) => {
          return {
            name: format(new Date(service.serviceDate), "eeeee dd/MM", {
              locale: fr,
            }),
            ca: service.paiements.reduce(
              (acc, paiement) => (acc += Number(paiement.amount)),
              0
            ),
          };
        });
      }
      setServData(tmpServData);
    };

    const changeServiceType = (type: string) => {
      console.log("change type to ", type);
      setServiceType(type);
      updateServData();
    };

    return (
      <>
        <select onChange={(e) => changeServiceType(e.target.value)}>
          <option value="day">Jour</option>
          <option value="week">Semaine</option>
          <option value="month">Mois</option>
        </select>
        <ResponsiveContainer width="100%" height={500}>
          <LineChart
            style={{
              backgroundColor: "#ffecb4",
              borderRadius: "18px",
            }}
            data={servData}
            margin={{
              right: 30,
              left: 5,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip
              formatter={(value, name, props) => Number(value).toFixed(2) + "€"}
            />
            <Legend />
            <Line
              type="monotone"
              dataKey="ca"
              stroke="#ffb79f"
              strokeWidth={3}
            />
          </LineChart>
        </ResponsiveContainer>
        <ul>
          {services
            .filter(
              (service: Service) =>
                service.serviceDate >= startDate &&
                service.serviceDate <= endDate &&
                (service.paiements.length > 0 || service.mouvements.length > 0)
            )
            .map((service: Service) => {
              return (
                <li key={service.id}>
                  <div className="service">
                    <h2>
                      {new Date(service.serviceDate).toLocaleDateString(
                        "fr-FR"
                      )}
                    </h2>
                    <p>
                      Total Paiements:{" "}
                      {service.paiements
                        .reduce(
                          (acc, paiement) => (acc += Number(paiement.amount)),
                          0
                        )
                        .toFixed(2)}
                      €
                    </p>
                    <p>
                      Total Mouvment:{" "}
                      {service.mouvements
                        .reduce(
                          (acc, mouvement) =>
                            (acc +=
                              mouvement.type == "Sortie"
                                ? -1 * Number(mouvement.amount)
                                : Number(mouvement.amount)),
                          0
                        )
                        .toFixed(2)}
                      €
                    </p>
                  </div>
                </li>
              );
            })}
        </ul>
      </>
    );
  };

  const ProductTab = () => {
    let articleColors = [
      "#f5b54a",
      "#F59C49",
      "#F5CB49",
      "#F57E49",
      "#F5DF49",
      "#ffb79f",
      "#FFA59E",
      "#FFC79E",
      "#FF9EBF",
      "#FFD89E",
      "#a5e0b6",
      "#AAE0A6",
      "#A6E0CA",
      "#C3E0A6",
      "#A6E0DE",
    ];
    /*
      Pain: #f5b54a
      #F59C49
      #F5CB49
      #F57E49
      #F5DF49

      Vienn: #ffb79f
      #FFA59E
      #FFC79E
      #FF9EBF
      #FFD89E

      Biscuit: #a5e0b6
      #AAE0A6
      #A6E0CA
      #C3E0A6
      #A6E0DE
    */
    let articlesPaiments = services
      .filter(
        (service: Service) =>
          service.serviceDate >= startDate && service.serviceDate <= endDate
      )
      .reduce((acc: any, service: Service) => {
        service.paiements.forEach((paiement) => {
          if (!(service.serviceDate in acc)) acc[service.serviceDate] = {};
          paiement.paiementItems.forEach((paiementItem) => {
            if (!(paiementItem.name in acc[service.serviceDate]))
              acc[service.serviceDate][paiementItem.name] = {};
            if (
              !(
                paiementItem.poids in
                acc[service.serviceDate][paiementItem.name]
              )
            )
              acc[service.serviceDate][paiementItem.name][paiementItem.poids] =
                [];
            acc[service.serviceDate][paiementItem.name][
              paiementItem.poids
            ].push(paiementItem);
          });
        });
        return acc;
      }, {} as any);

    let artGenList = {} as any;
    const dataArticle = [] as any[];
    Object.entries(articlesPaiments).forEach(
      ([serviceDate, articlePaiment]: [string, any]) => {
        Object.entries(articlePaiment).forEach(
          ([articleName, poidsObj]: [string, any]) => {
            Object.entries(poidsObj).forEach(
              ([poids, paiementItems]: [string, any]) => {
                let barData = {} as any;
                if (!artGenList[articleName])
                  artGenList[articleName] =
                    articleColors[
                      Object.keys(artGenList).length % articleColors.length
                    ];
                let articleKey = articleName + " - " + poids + "g";
                barData.name = articleKey;
                barData["Quantité"] = paiementItems.reduce(
                  (acc: number, paiementItem: any) => {
                    acc += paiementItem.qtt;
                    return acc;
                  },
                  0
                );
                let dArticle = dataArticle.find(
                  (data) => data.name === articleKey
                );
                dArticle
                  ? (dArticle["Quantité"] += barData["Quantité"])
                  : dataArticle.push(barData);
              }
            );
          }
        );
      }
    );
    return (
      <div className="chart-cont">
        <h2>Produits</h2>
        <ResponsiveContainer width="100%" height={500}>
          <BarChart
            style={{
              backgroundColor: "#ffecb4",
              borderRadius: "18px",
            }}
            data={dataArticle.sort((a, b) => {
              let aT = a.name.split(" - ");
              let bT = b.name.split(" - ");
              let aName = aT[0] + "-" + aT[1].replace("g", "").padStart(4, "0");
              let bName = bT[0] + "-" + bT[1].replace("g", "").padStart(4, "0");
              return aName.localeCompare(bName);
            })}
            margin={{
              top: 10,
              right: 30,
              left: 10,
              bottom: 5,
            }}
          >
            <XAxis dataKey="name" />
            <YAxis allowDecimals={false} />
            <Tooltip />
            <Bar dataKey="Quantité" fill="#8884d8" label>
              {dataArticle.map((articleName, index) => {
                return (
                  <Cell
                    key={`cell-${index}`}
                    fill={artGenList[articleName.name.split(" - ")[0]]}
                  />
                );
              })}
              <LabelList dataKey="name" position="top" />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  const handleDateChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    type === "start"
      ? setStartDate(e.target.value)
      : setEndDate(e.target.value);
  };

  const getExport = async () => {
    let arrayBuffer = await genExport(startDate, endDate);
    var blob = new Blob([arrayBuffer], {
      type: "text/csv;charset=utf-8",
    });
    var url = URL.createObjectURL(blob);
    window.open(url);
  };

  return (
    <main>
      <div className="dash-wrapper">
        <h1>Dashboard</h1>
        <p>
          Du{" "}
          <input
            type="date"
            value={startDate}
            onChange={(e) => handleDateChange(e, "start")}
          />{" "}
          au{" "}
          <input
            type="date"
            value={endDate}
            onChange={(e) => handleDateChange(e, "end")}
          />
        </p>
        <ul>
          <li onClick={() => setTab("service")}>Services</li>
          <li onClick={() => setTab("produit")}>Produits</li>
          <li onClick={() => setTab("paiement")}>Paiements</li>
        </ul>
        {tab === "service" && <ServiceTab />}
        {tab === "produit" && <ProductTab />}
        {tab === "paiement" && (
          <div>
            <button onClick={getExport}>export</button>
          </div>
        )}
      </div>
    </main>
  );
};
