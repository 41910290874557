import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

import { Article, Recette } from "../utils/types";

interface ArticlePromo {
  recetteId: number;
  articles: Article[];
}

export interface RecetteState {
  recettes: Recette[];
  status: "idle" | "loading" | "loaded";
}

const initialState: RecetteState = {
  recettes: [],
  status: "idle",
};

export const recetteSlice = createSlice({
  name: "recette",
  initialState: initialState,
  reducers: {
    patchArticles: (state, action: PayloadAction<ArticlePromo>) => {
      state.recettes = state.recettes.map((recette: Recette) => {
        if (recette.id === action.payload.recetteId) {
          recette.article = action.payload.articles;
        }
        return recette;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRecettes.fulfilled, (state, action) => {
      state.status = "loaded";
      state.recettes = action.payload;
      return state;
    });
    builder.addCase(fetchRecettes.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(fetchRecettes.rejected, (state, action) => {
      console.log("rejected");
      console.log(action);
    });
  },
});

export const fetchRecettes = createAsyncThunk(
  "article/fetchRecettes",
  async () =>
    await axios
      .get(process.env.REACT_APP_API_BASE_URL + "/recette")
      .then((response) => response.data)
);

export const { patchArticles } = recetteSlice.actions;
export default recetteSlice.reducer;
