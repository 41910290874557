import { useState, useEffect, useReducer } from "react";
import { fetchCurrentCommandes, patchCommande } from "../utils/api";

import { Commande, Client } from "../utils/types";

import "../styles/Commandes.css";

export type SortedCommandeItem = {
  quantity: number;
  poids: number;
  prix: number;
};

export type SortedCommande = {
  id: number;
  isValidated: boolean;
  isFullfilled: boolean;
  client: Client;
  commandeItems: {
    [recetteName: string]: SortedCommandeItem[];
  };
};

export type SortedCommandes = {
  [commandeDate: string]: SortedCommande[];
};

const Commandes = () => {
  const commandeReducer = (state: SortedCommandes, event: Commande[]) => {
    event.forEach((commande) => {
      let commandeDateString = new Date(commande.commandeDate)
        .toISOString()
        .split("T")[0];
      if (!state[commandeDateString]) {
        state[commandeDateString] = [];
      }
      if (
        commande.commandeItems &&
        commande.commandeItems.length > 0 &&
        commande.commandeItems[0].article
      ) {
        let commandeItemsState: { [recetteName: string]: any } = {};
        commande.commandeItems.forEach((commandeItem) => {
          if (commandeItem.article.recette) {
            if (!commandeItemsState[commandeItem.article.recette.name])
              commandeItemsState[commandeItem.article.recette.name] = [];
            commandeItemsState[commandeItem.article.recette.name].push({
              quantity: commandeItem.quantity,
              poids: commandeItem.article.poids,
              prix: commandeItem.article.prix,
            });
          }
        });
        let commandeState = {
          id: commande.id,
          isValidated: commande.isValidated,
          isFullfilled: commande.isFullfilled,
          client: commande.client,
          commandeItems: {},
        };
        commandeState.commandeItems = commandeItemsState;

        if (!state[commandeDateString].find((cmd) => cmd.id == commande.id)) {
          state[commandeDateString].push(commandeState);
        } else {
          state[commandeDateString] = state[commandeDateString].map((cmd) => {
            if (cmd.id == commandeState.id) {
              cmd = commandeState;
            }
            return cmd;
          });
        }
      }
    });
    setIsRendering(false);
    return state;
  };
  const [isRendering, setIsRendering] = useState(false);
  const [commandes, setCommandes] = useReducer(commandeReducer, {});

  useEffect(() => {
    fetchCurrentCommandes().then((commandesDb: Commande[]) => {
      setIsRendering(true);
      setCommandes(commandesDb);
    });
  }, []);

  const handlePatch = async (commandeId: number, patchCmd: any) => {
    let nCommande = await patchCommande(commandeId, patchCmd);
    setIsRendering(true);
    setCommandes([nCommande]);
  };

  const CommandeItem = (props: { commande: SortedCommande }) => {
    const { commande } = props;
    const [isExpanded, setIsExpanded] = useState(false);
    return (
      <li key={commande.id}>
        <div className="commandeHead">
          <p className="cmd-client" onClick={() => setIsExpanded(!isExpanded)}>
            #{commande.id} {commande.client.name}
          </p>
          <div className="cmdHeadBtnCont">
            <button
              onClick={() => {
                handlePatch(commande.id, {
                  isValidated: !commande.isValidated,
                });
              }}
              className={
                commande.isValidated
                  ? "cmd-btn cmd-btn-yes"
                  : "cmd-btn cmd-btn-no"
              }
            >
              Validée
            </button>
            <button
              onClick={() => {
                handlePatch(commande.id, {
                  isFullfilled: !commande.isFullfilled,
                });
              }}
              className={
                commande.isFullfilled
                  ? "cmd-btn cmd-btn-yes"
                  : "cmd-btn cmd-btn-no"
              }
            >
              Récupérée
            </button>
          </div>
        </div>
        {isExpanded && (
          <div>
            <h2>Articles</h2>
            {Object.entries(commande.commandeItems).map(
              ([recetteName, commandeItems]) => {
                return (
                  <>
                    <p>{recetteName}</p>
                    <ul>
                      {commandeItems
                        .sort(
                          (a: SortedCommandeItem, b: SortedCommandeItem) =>
                            a.poids - b.poids
                        )
                        .map((commandeItem) => (
                          <li key={commande.id + "-" + commandeItem.poids}>
                            <p>
                              {commandeItem.quantity} x {commandeItem.poids}g
                            </p>
                          </li>
                        ))}
                    </ul>
                  </>
                );
              }
            )}
          </div>
        )}
      </li>
    );
  };

  return (
    <main>
      <div className="cmd-wrapper">
        <h1>Commandes</h1>
        {!isRendering && (
          <ul className="cmd-list">
            {Object.entries(commandes).map(([commandeDate, commandeD]) => (
              <li key={commandeDate}>
                <div className="dateHead">
                  <p className="cmd-dateHead">
                    {new Date(commandeDate).toLocaleDateString("fr-FR", {
                      weekday: "long",
                      day: "numeric",
                      month: "long",
                    })}
                  </p>
                </div>
                <ul className="commande-list">
                  {commandeD
                    .filter((sCmd) => !sCmd.isFullfilled)
                    .map((commande) => (
                      <CommandeItem key={commande.id} commande={commande} />
                    ))}
                  {commandeD
                    .filter((sCmd) => sCmd.isFullfilled)
                    .map((commande) => (
                      <CommandeItem key={commande.id} commande={commande} />
                    ))}
                </ul>
              </li>
            ))}
          </ul>
        )}
      </div>
    </main>
  );
};

export default Commandes;
