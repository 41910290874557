import { useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation();
  return (
    <ul className="header-list">
      <li>
        <a
          href="/recettes"
          id="header-recette"
          title="Recette"
          className={location.pathname == "/recettes" ? "header-active" : ""}
        ></a>
      </li>
      <li>
        <a
          href="/ingredients"
          id="header-ing"
          title="Ingrédients"
          className={location.pathname == "/ingredients" ? "header-active" : ""}
        ></a>
      </li>
      <li>
        <a
          href="/basepate"
          id="header-basepate"
          title="Base Pâte"
          className={location.pathname == "/basepate" ? "header-active" : ""}
        ></a>
      </li>
      <li>
        <a
          href="/articles"
          id="header-article"
          title="Articles"
          className={location.pathname == "/articles" ? "header-active" : ""}
        ></a>
      </li>
      <li>
        <a
          href="/commandes"
          id="header-commande"
          title="Commandes"
          className={location.pathname == "/commandes" ? "header-active" : ""}
        ></a>
      </li>
      <li>
        <a
          href="/clients"
          id="header-clients"
          title="Clients"
          className={location.pathname == "/clients" ? "header-active" : ""}
        ></a>
      </li>
      <li>
        <a
          href="/dashboard"
          id="header-dashboard"
          title="Dashboard"
          className={location.pathname == "/dashboard" ? "header-active" : ""}
        ></a>
      </li>
    </ul>
  );
};

export default Header;
