import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import { fetchRecettes, patchArticles } from "../redux/recetteSlice";
import { updateRecette } from "../utils/api";

import "../styles/Articles.css";
import { Article, Recette } from "../utils/types";

const Articles = () => {
  const dispatch = useDispatch<AppDispatch>();
  const recettes = useSelector((state: RootState) => state.recette.recettes);
  const [typeSelected, setTypeSelected] = useState<string>("all");

  useEffect(() => {
    dispatch(fetchRecettes());
  }, [dispatch]);

  const ArticleCard = ({
    article,
    handleArticle,
    handleRemove,
  }: {
    article: Article;
    handleArticle: (article: Article) => void;
    handleRemove: (article: Article) => void;
  }) => {
    const [promo, setPromo] = useState<number>(article.promoPrix || 0);
    const [prix, setPrix] = useState<number>(article.prix);
    const [hasPromo, setHasPromo] = useState(article.hasPromo);
    const [poids, setPoids] = useState<number>(article.poids);

    const handlePromo = (e: React.ChangeEvent<HTMLInputElement>) => {
      let tmPromo = parseFloat(e.target.value);
      if (isNaN(tmPromo)) tmPromo = 0;
      setPromo(tmPromo);
      handleArticle({
        ...article,
        poids,
        prix,
        promoPrix: tmPromo,
        hasPromo,
      });
    };

    const handlePrix = (e: React.ChangeEvent<HTMLInputElement>) => {
      let tmPrix = parseFloat(e.target.value);
      if (isNaN(tmPrix)) tmPrix = 0;
      setPrix(tmPrix);
      handleArticle({
        ...article,
        poids,
        prix: tmPrix,
        promoPrix: promo,
        hasPromo,
      });
    };

    const handlePoids = (e: React.ChangeEvent<HTMLInputElement>) => {
      let tmPoids = parseFloat(e.target.value);
      if (isNaN(tmPoids)) tmPoids = 0;
      setPoids(tmPoids);
      handleArticle({
        ...article,
        poids: tmPoids,
        prix,
        promoPrix: promo,
        hasPromo,
      });
    };

    const handleHasPromo = (e: any) => {
      let tmpHasPromo = !hasPromo;
      setHasPromo(tmpHasPromo);
      handleArticle({
        ...article,
        poids,
        prix,
        promoPrix: promo,
        hasPromo: tmpHasPromo,
      });
    };

    return (
      <tr className="art-card-item">
        <td>
          <input
            type="text"
            name="poids"
            className="art-edit"
            defaultValue={article.poids + "g"}
            onChange={handlePoids}
            placeholder="Poids"
          />
        </td>
        <td>
          <input
            type="text"
            name="prix"
            className="art-edit"
            defaultValue={article.prix.toFixed(2) + "€"}
            onChange={handlePrix}
            placeholder="Prix"
          />
        </td>
        <td>
          <div className="art-hasPromo">
            <input
              type="checkbox"
              name="hasPromo"
              checked={hasPromo}
              onChange={handleHasPromo}
            />
            <label
              htmlFor="hasPromo"
              id="hasPromo"
              className="promo-label"
              onClick={handleHasPromo}
            >
              Promo
            </label>
          </div>
          {hasPromo && (
            <input
              type="text"
              name="promo"
              className="art-edit"
              defaultValue={
                article.promoPrix && article.promoPrix.toFixed(2) + "€"
              }
              onChange={handlePromo}
              placeholder="Prix promo"
            />
          )}
        </td>
        <td>
          <button className="art-del" onClick={() => handleRemove(article)}>
            Supprimer
          </button>
        </td>
      </tr>
    );
  };

  const RecetteCont = ({ recette }: { recette: Recette }) => {
    const [articles, setArticles] = useState<Article[]>(recette.article || []);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const handleArticle = (article: Article) => {
      setArticles(
        articles.map((art: Article) =>
          art.id === article.id ? { ...art, ...article } : art
        )
      );
    };

    const handleRemove = (article: Article) => {
      setArticles(articles.filter((art: Article) => art.id !== article.id));
    };

    const addArticle = () => {
      setArticles([
        ...articles,
        {
          id: 0,
          poids: 0,
          prix: 0,
          promoPrix: 0,
          hasPromo: false,
          recette: recette,
        },
      ]);
    };

    const handleSave = async () => {
      if (!recette.id) return;
      setIsSaving(true);
      let patchRecette = { ...recette };
      patchRecette.article = articles;
      const patchedRecette = await updateRecette(patchRecette);
      setIsSaving(false);
      dispatch(
        patchArticles({
          recetteId: patchedRecette.id,
          articles: patchedRecette.article,
        })
      );
    };

    return (
      <div className="recette-art-cont" id={"rct-" + recette.id}>
        <h2>{recette.name}</h2>
        <div className="recette-art-wrapper">
          <table className="art-row">
            <thead>
              <tr>
                <th>Poids</th>
                <th>Prix</th>
                <th>Promo</th>
              </tr>
            </thead>
            <tbody>
              {articles &&
                articles.map((article: Article) => (
                  <ArticleCard
                    key={article.id}
                    article={article}
                    handleArticle={handleArticle}
                    handleRemove={handleRemove}
                  />
                ))}
              <tr>
                <td colSpan={4}>
                  <button className="art-add" onClick={addArticle}>
                    Ajouter
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="art-tag-cont">
            <h4 className={"art-tag-head art-head-" + recette.type}>
              {recette.name}
            </h4>
            {articles &&
              articles.map((article: Article) => (
                <div className="art-tag" key={"sum-" + article.id}>
                  <p>{article.poids}g</p>
                  {article.hasPromo ? (
                    <p className="prix-tag">
                      <span className="barred">{article.prix.toFixed(2)}€</span>{" "}
                      {article.promoPrix.toFixed(2)}€{" "}
                      <span className="reduc-tag">
                        (
                        {(article.prix - article.promoPrix) / article.prix > 0
                          ? "-"
                          : "+"}
                        {Math.abs(
                          Math.round(
                            ((article.prix - article.promoPrix) /
                              article.prix) *
                              100
                          )
                        )}
                        %)
                      </span>
                    </p>
                  ) : (
                    <p className="prix-tag">{article.prix.toFixed(2)}€</p>
                  )}
                </div>
              ))}
            <button className="art-save" onClick={handleSave}>
              {isSaving ? "..." : "Sauvegarder"}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const TypeRow = ({ type }: { type: string }) => {
    return (
      <div className="type-row">
        {recettes &&
          recettes
            .filter((recette: Recette) => recette.type === type)
            .map((recette: Recette) => (
              <RecetteCont key={recette.id} recette={recette} />
            ))}
      </div>
    );
  };

  /*
   */

  return (
    <main>
      <div className="art-wrapper">
        <h1>Articles</h1>
        <select
          value={typeSelected}
          onChange={(e) => setTypeSelected(e.target.value)}
        >
          <option value="all">Tous</option>
          <option value="pain">Pain</option>
          <option value="viennoiserie">Viennoiserie</option>
          <option value="biscuit">Biscuit</option>
        </select>
        {typeSelected === "all" ? (
          <>
            <TypeRow key="pain" type="pain" />
            <TypeRow key="viennoiserie" type="viennoiserie" />
            <TypeRow key="biscuit" type="biscuit" />
          </>
        ) : (
          <TypeRow type={typeSelected} />
        )}
      </div>
    </main>
  );
};

export default Articles;
