import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchRecette, updateRecette } from "../utils/api";
import { Recette } from "../utils/types";

import "../styles/PublicRecette.css";

const PublicRecette = () => {
  const [recette, setRecette] = useState<Recette>();
  const [name, setName] = useState<string>("");
  const [desc, setDesc] = useState<string>("");
  const [isPublic, setIsPublic] = useState<boolean>(false);
  const [img, setImg] = useState<string>("");
  const [type, setType] = useState<string>("pain");

  let { recetteId } = useParams();

  useEffect(() => {
    fetchRecette(recetteId).then((rct) => {
      setRecette(rct);
      setName(rct.name);
      setDesc(rct.desc);
      setIsPublic(rct.isPublic);
      setImg(rct.img);
      setType(rct.type);
    });
  }, [recetteId]);

  const handleEditRecette = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (recette) {
      let tmpRecette: Recette = {
        ...recette,
        name,
        desc,
        isPublic,
        img,
        type,
      };
      const updatedRecette = await updateRecette(tmpRecette);
      setRecette(updatedRecette);
    }
  };

  return (
    <main>
      <div className="rct-wrapper">
        <h1>Editeur de recettes</h1>
        <form className="rct-form" onSubmit={handleEditRecette}>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <select value={type} onChange={(e) => setType(e.target.value)}>
            <option value="pain">Pain</option>
            <option value="viennoiserie">Viennoiserie</option>
            <option value="biscuit">Biscuit</option>
            <option value="autre">Autre</option>
          </select>
          <textarea value={desc} onChange={(e) => setDesc(e.target.value)} />
          <p>
            <input
              type="checkbox"
              checked={isPublic}
              onChange={() => setIsPublic(!isPublic)}
            />
            <label onClick={() => setIsPublic(!isPublic)}>Public</label>
          </p>
          <img
            src={"http://188.165.204.139:5801/img/photo-pain/" + img}
            alt={name}
          />
          <button>Enregistrer</button>
        </form>
      </div>
    </main>
  );
};

export default PublicRecette;
