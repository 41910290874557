import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Service } from "../utils/types";

export interface ServiceState {
  services: Service[];
  firstService: string;
  lastService: string;
}

const initialState: ServiceState = {
  services: [],
  firstService: "",
  lastService: "",
};

export const serviceSlice = createSlice({
  name: "service",
  initialState: initialState,
  reducers: {
    setServices: (state, action: PayloadAction<Service[]>) => {
      state.services = action.payload;
      state.firstService = new Date(
        Math.min(
          ...state.services.map((service) =>
            new Date(service.serviceDate).getTime()
          )
        )
      ).toDateString();
      state.lastService = new Date(
        Math.max(
          ...state.services.map((service) =>
            new Date(service.serviceDate).getTime()
          )
        )
      ).toDateString();
    },
  },
});

export const { setServices } = serviceSlice.actions;
export default serviceSlice.reducer;
