import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { recetteSlice } from "./redux/recetteSlice";
import { serviceSlice } from "./redux/serviceSlice";

const rootReducer = combineReducers({
  recette: recetteSlice.reducer,
  service: serviceSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
